import "./styles.css";
import logo from "./assets/logo.png";
import churchBg from "./assets/ig-family.jpg";

export default function App() {
  
  return (
    <div className="App">
      <img style={{
        position: "absolute",
        width: '80px',
        marginLeft: 'auto',
        marginRight: 'auto',
        right: '0',
        left: '0',
        filter: "drop-shadow(white 5px 2px 20px)",
        }}
        src={logo}
        alt="Logo da Igreja"
      />
      <section
        style={{
          display: "flex",
          flexDirection: "column",
          height: "90vh",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ position: "relative", top: "40vh", textAlign: "center" }}>
          <h1
            className="title"
            style={{
              marginBottom: "12px",
              color: "#fff",
              filter: "drop-shadow(black 5px 2px 25px)",
            }}
          >
            Igreja da Família
          </h1>
          <button
            style={{
              backgroundColor: "#9DB16F",
              padding: "12px",
              color: "#fff",
              fontWeight: "600",
            }}
          >
            <a href="#visit-us">Visite-nos</a>
          </button>
        </div>
        <p
          style={{
            width: '100%',
            boxSizing: 'border-box',
            backgroundColor: "#D5C386",
            padding: "12px 16px",
            textAlign: "center",
          }}
        >
          Antes, seguindo a verdade em amor, cresçamos em tudo naquele que é a
          cabeça, Cristo. <strong>EFÉSIOS 4:15</strong>
        </p>
        <img
          src={churchBg}
          alt=""
          style={{
            width: "100%",
            position: "fixed",
            zIndex: -10,
            height: "90vh",
            objectFit: "cover",
            objectPosition: "center 65%",
          }}
        />
      </section>

      <section
        id="visit-us"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "100vh",
          alignItems: "center",
          backgroundColor: "#9DB16F",
        }}
      >
        <div style={{ padding: "48px 0" }}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3515.6288429405777!2d-48.67328822373744!3d-28.218587049030138!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9526cb01d7217bbb%3A0x9cd969fd4e95f078!2sIgreja%20da%20Fam%C3%ADlia!5e0!3m2!1spt-BR!2sbr!4v1714093689325!5m2!1spt-BR!2sbr"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            className="map"
            referrerpolicy="no-referrer-when-downgrade"
          />
          <ul
            style={{
              display: "flex",
              flexDirection: "column",
              listStyle: "none",
              justifyContent: "center",
              gap: "12px",
              fontWeight: "500",
              textDecoration: "underline",
              color: "blue",
              cursor: "pointer",
            }}
          >
            <li>
              <a href="https://maps.app.goo.gl/qX1UPLLKkzHQJm3v9" target="blank">
                Localização
              </a>
            </li>
            <li>
              <a href="https://instagram.com/igrejafamilia.imb" target="blank">Instagram</a>
            </li>
          </ul>
        </div>
      </section>

      <footer style={{ padding: "12px", textAlign: "center", backgroundColor: '#fff' }}>
        <p> © 2024 Igreja da Família. Todos os direitos reservados. </p>
      </footer>
    </div>
  );
}
